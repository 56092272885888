.img-magnifier-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.magnifier-img {
    width: 100%; /* Make sure the image takes up 100% of its container width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.magnifier-image {
    width: 200px;
    height: 200px;
    border: 1px solid black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
