.nav-icons {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.2rem 0.5rem ;
    color: black;
}


.search-wrap {
    width: 400px;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
}

.search-wrap .search-box {
    position: relative;
    width: 400px;
    height: 40px;
}


@media (max-width: 500px) {
    .search-wrap {
        width: 350px;
    }

    .search-wrap .search-box {
        width: 350px;
    }
}

@media (max-width: 440px) {
    .search-wrap {
        width: 300px;
    }

    .search-wrap .search-box {
        width: 300px;
    }
}

@media (max-width: 360px) {
    .search-wrap {
        width: 280px;
    }

    .search-wrap .search-box {
        width: 280px;
    }
}

.search-wrap .search-box .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: #e20613;
    z-index: 1;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.search-wrap .search-box .btn:hover {
    background: #dd3c47;   
}
