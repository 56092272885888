.mySwiper {
  width: 100%;
  height: 500px; /* Set a fixed height for the swiper */
}

.image-container {
  position: relative;
  overflow: hidden;
  padding: 40px;
  width: 100%; /* Ensure the container takes full width */
  height: 100%; /* Ensure the container takes full height */
}

.image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: none; /* Remove max-width to prevent shrinking */
  max-height: none; /* Remove max-height to prevent shrinking */
}

  /* Add styles for Swiper navigation arrows */
.swiper-button-prev,
.swiper-button-next {
  color: #e20613; /* Set the color of the arrows */
}

/* Customize the size and appearance of the navigation arrows */
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 24px; /* Adjust the size of the arrows */
}

/* Change the background color of the navigation arrows on hover */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #f1d0d2; /* Adjust the background color on hover */
}

/* Optionally, you can adjust the position of the navigation arrows */
.swiper-button-prev {
  left: 5px; /* Adjust the left position */
}

.swiper-button-next {
  right: 5px; /* Adjust the right position */
}

.swiper-pagination-bullet {
    width: 8px; /* Adjust the width of the bullets */
    height: 8px; /* Adjust the height of the bullets */
    background-color: #999999; /* Set the color of inactive bullets */
    opacity: 1; /* Set the opacity of inactive bullets */
    margin: 0 6px; /* Adjust the spacing between bullets */
    cursor: pointer;
  }
  
  /* Style for active pagination bullet */
  .swiper-pagination-bullet-active {
    background-color: #e20613; /* Set the color of the active bullet */
  }